<template>
    <div class="letter">
        <BackBar :pageTit="to.title" />
        <div class="letterMain">
            <div v-for="item in msgs" :key="'item'+item.id">
            <div class="right" v-if="item.from_user_id==me.id">
                <div class="reply_con">{{item.title}}</div>
                <div class="tx" :style="{'background-image':'url('+me.headimgurl+')'}"></div>
            </div>
            <div class="left" v-else>
                <div class="tx" :style="{'background-image':'url('+to.headimgurl+')'}"></div>
                <div class="reply_con">{{item.title}}</div>
            </div>
            </div>
        </div>
        <div class="letter_foot">
            <input placeholder="回复消息" v-model="msg">
            <div class="send_more"></div>
            <div class="sendBtn" @click="send">发送</div>
        </div>
    </div>
</template>
<script>
// @ is an alias to /src
import BackBar from "@/components/BackBar.vue";
import Api from '@/api'
import Vue from 'vue'
export default {
    name:'Letter',
    data(){
        return{
            to:{},
            me:{},
            msgs:[],
            msg:'',
            refreshHand:false
        }
    },
    methods:{
        send(){
            if(this.msg==''){

            }else{
                var that=this;
                Api.go('new_msg',{to_userid:this.$route.params.toid,msg:this.msg},(res)=>{
                    that.msg='';
                    that.newRefresh();    
                })
                
            }
        },
        newRefresh(){
            if(this.refreshHand){
                clearTimeout(this.refreshHand);
            }
             var that=this
            Api.go('msg_after',{userid:this.$route.params.toid,msgid:this.maxid},(res)=>{
                for(var i in res.rows){
                    that.msgs.push(res.rows[i]);
                }
                 that.refreshHand=setTimeout(function(){
        
                    that.newRefresh()
               
                
                 },3000)
            })
           
           
        }
    },
     computed: {
         minid(){
            if(this.msgs.length==0){
                return 0;
            }else{
                return this.msgs[0].id
            }
         },
         maxid(){

            if(this.msgs.length==0){
                return 0;
            }else{
                return this.msgs[this.msgs.length-1].id
            }
         }
     },
    destroyed(){
        if(this.refreshHand){
            clearTimeout(this.refreshHand);
        }
    },
    mounted(){
        var that=this;
        Api.go('msg_before',{userid:this.$route.params.toid},(res)=>{
            that.to=res.to
            that.me=res.me
            that.msgs=res.rows
            that.newRefresh()
        })
    },
    components: {
        BackBar,
    },
}
</script>
<style lang="less" scoped>
    .letterMain{
        padding: .32rem;
        .left,.right{
            display: flex;
            margin-bottom: .48rem;
            width: 100%;
        }
        .reply_con{
            font-size: .32rem;
            max-width: 4.4rem;
            padding: .24rem;
            box-shadow: 0 .04rem .08rem 0 rgba(0, 0, 0, 0.1);
        }
        .tx{
            width: .96rem;
            height: .96rem;
            border-radius: 50%;
            background: gold;
        }
        .right {
            float: right;
            justify-content: flex-end;
            .reply_con{   
                background: #F65102;
                border-radius: .48rem .48rem .16rem .48rem;
                color: #fff;
            }
            .tx{
                margin-left: .24rem;
                background-size: cover;
            }
        }
        .left{
            float: left;
            .reply_con{
                background: #fff;
                border-radius: .48rem .48rem .48rem .16rem;
                color: #333;
            }
            .tx{
                
                background-size: cover;
                margin-right: .24rem;
            }
        }
    }
    .letter_foot{
        width: 100%;
        height: .98rem;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 0px 0px #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:0 .32rem;
        box-sizing: border-box;
        position: fixed;
        bottom: 0;
        left: 0;
        input{
            width: 4.66rem;
            height: .62rem;
            background: #FFF6F1;
            border-radius: .3rem;
            font-size: .24rem;
            padding: 0 .24rem;
            box-sizing: border-box;
        }
        .send_more{
            width: .48rem;
            height: .48rem;
            background:url(~@/assets/img/send_add.png) no-repeat;
            background-size: 100% 100%;
        }
        .sendBtn{
            width: 1.24rem;
            height: .62rem;
            line-height: .62rem;
            background: #F65102;
            border-radius: .3rem;
            font-size: .28rem;
            color: #fff;
            text-align: center;
        }
    }
</style>